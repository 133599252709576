/* == PROJECT / APP / BASICS ====================================================== */
/* == COMMON ====================================================== */
/* == FLEX ====================================================== */
/* == COLUMN ====================================================== */
/* == GAP ====================================================== */
/* == TEXT & FONTS ====================================================== */
/* == BOX-SHADOW ====================================================== */
/* == GRADIENT ====================================================== */
/* == ROTATE ====================================================== */
/* == SCALE ====================================================== */
/* == CALC ====================================================== */
/* == CLIP-PATH ====================================================== */
/* == TRANSFORM ====================================================== */
/* == TRANSITION ====================================================== */
/* @include transition(opacity, 0.5s, ease-out, 0s); */
/* == ANIMATION ====================================================== */
/* == CURSOR ====================================================== */
/* == IMG ====================================================== */
/* == BG-IMG ====================================================== */
/* == Museo Slab ====================================================== */
@font-face {
  font-family: "Museo Slab";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/Museo-Slab/Museo-Slab-500.woff2?1744355188281") format("woff2");
}
/* == Exo 2 ====================================================== */
/* latin-ext */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHov4okm5zmbt5LG-Ic5VArlT8bdw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHov4okm5zmbt5LG-wc5VArlT8.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt7nCvs1wH8DnzcjTrA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt7nCvs7wH8Dnzcj.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt6DCfs1wH8DnzcjTrA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt6DCfs7wH8Dnzcj.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHmv4okm5zmbtYmK-4W4nIppT4.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHmv4okm5zmbtYoK-4W4nIp.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt7bCPs1wH8DnzcjTrA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt7bCPs7wH8Dnzcj.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt73D_s1wH8DnzcjTrA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt73D_s7wH8Dnzcj.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt6TDvs1wH8DnzcjTrA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt6TDvs7wH8Dnzcj.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt6PDfs1wH8DnzcjTrA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt6PDfs7wH8Dnzcj.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt6rDPs1wH8DnzcjTrA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/exo2/v6/7cHrv4okm5zmbt6rDPs7wH8Dnzcj.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* == Open Sans ====================================================== */
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OXOhpKKSTj5PW.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/opensans/v17/mem8YaGs126MiZpBA-UFW50bf8pkAp6a.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOXOhpKKSTj5PW.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOXOhpKKSTj5PW.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/opensans/v17/mem5YaGs126MiZpBA-UN8rsOXOhpKKSTj5PW.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.menutecs-cloud.workers.dev/s/opensans/v17/mem5YaGs126MiZpBA-UN8rsOUuhpKKSTjw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}